<template>
  <div class="message-index">
    <el-card shadow="hover" header="短信剩余条数" class="x-card-title">
      <div style="color:#393C41">
        <span style="font-size: 32px">{{ smsNum }}</span>
        <span style="font-size: 20px"> 条</span>
      </div>
      <el-button size="small" @click="czShow" type="primary">购买短信套餐</el-button>
    </el-card>

    <el-card shadow="hover" header="短信签名" class="x-card-title">
      <div style="color:#393C41">
        <span style="font-size: 32px">{{ smsSignCount }}</span>
        <span style="font-size: 20px"> 个</span>
      </div>
      <div>
        <el-button type="text" @click="gosq">签名申请</el-button>
        <span style="margin: 0 4px; display: inline-block; transform: translateY(-1.5px)">|</span>
        <el-button type="text" @click="gogl">管理签名</el-button>
      </div>
    </el-card>

    <el-card shadow="hover" header="商家签名配置" class="x-card-title">
      <div>
        创建新站点/卡券后，默认签名为【山水互联】，您可配置指定站点/卡券使用其他已申请成功的签名
      </div>
      <el-button type="text" class="shou" @click="gozd">站点签名配置</el-button>
    </el-card>

    <bugMessage ref="buyMsg"></bugMessage>
  </div>
</template>

<script>
import bugMessage from '../buymessage';
import * as API_account from '@/api/account';

export default {
  data () {
    return {
      flag: false,
      smsNum: 1,
      smsSignCount: 1
    };
  },
  components: {
    bugMessage
  },
  created () {
    this.getMsgData();
  },
  methods: {
    getMsgData () {
      API_account.Management().then(res => {
        this.smsNum = res.smsNum;
        this.smsSignCount = res.smsSignCount;
      });
    },
    gozd () {
      this.$router.push({ path: '/finance/Messages/messagePei' });
    },
    czShow () {
      this.$refs.buyMsg.show();
    },
    gosq () {
      this.$router.push({
        path: '/finance/Messages/messageManage',
        query: { num: 1 }
      });
    },
    gogl () {
      this.$router.push({ path: '/finance/Messages/messageManage' });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  margin-bottom: 16px;
}
</style>
